.wrapper {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
  padding: var(--desktopSidePaddingSmall);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: scroll;
}

.pageLink {
  width: 100%;
  text-align: left;
  & h1 {
    font-size: 20px;
    margin-bottom: 0;
  }
}

.exitButton {
  width: 100%;
  text-align: right;
  font-size: 30px;
  cursor: pointer;
  &:hover {
    color: var(--marketplaceColor);
  }
}
