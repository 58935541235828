.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgb(34, 34, 35, 0.48);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  position: relative;
  background-color: white;
  color: black;
  display: flex;
  @media (max-width: 1025px) {
    flex-direction: column;
  }

  @media (max-width: 1025px) {
    width: 90vw;
    max-height: 95vh;
    overflow: scroll;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
}

.content::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.image {
  object-fit: cover;
  width: 300px;

  @media (max-width: 1025px) {
    width: 100%;
    max-height: 40vh;
    object-position: top;
  }
}

.textWrapper {
  padding: 20px 20px 60px 20px;
  max-width: 500px;

  & h2 {
    font-size: 35px;
    line-height: 40px;
  }

  @media (max-width: 1025px) {
    padding: 20px;

    & h2 {
      font-size: 30px;
      line-height: 35px;
    }
  }
}

.ctaButton {
  composes: buttonDefault from global;
  max-width: 200px;
  margin-top: 50px;
  &:hover {
    text-decoration: none;
  }
}

.close {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 1025px) {
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
  }
}

.closeIcon {
  height: 30px;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
}
