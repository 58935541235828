.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffa768;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  color: black;

  & a {
    color: black;
    font-weight: 700;
    text-decoration: underline;
    margin-left: 10px;
  }

  @media (max-width: 1025px) {
    flex-direction: column;
    align-items: flex-start;
    & a {
      margin: 0;
    }
  }
}

.relativeClass {
  position: relative;
}
