.wrapper {
  position: absolute;
  bottom: 20px;
  right: 10px;
  border: 1px solid var(--colorGrey100);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 2px 10px;
  cursor: pointer;
  &:hover {
    background-color: var(--marketplaceColor);
    border: 1px solid var(--marketplaceColor);
  }
}

.likedWrapper {
  composes: wrapper;
  background-color: var(--marketplaceColor);
  border: 1px solid var(--marketplaceColor);
  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid var(--colorGrey100);
  }
}

.likeCount {
  color: rgb(74, 74, 79);
  margin-left: 10px;
  font-size: 18px;
}

.dislikeCount {
  color: rgb(74, 74, 79);
  margin: 0 0 0 5px;
}

.likeIcon {
  cursor: pointer;
  height: 20px;
}

.likeActive {
  color: var(--colorSuccess);
  &:hover {
    color: rgb(74, 74, 79);
  }
}

.dislikeButton {
  color: rgb(74, 74, 79);
  cursor: pointer;
  transform: scale(0.8);
  &:hover {
    color: var(--colorFail);
  }
}

.dislikeActive {
  color: var(--colorFail);
  &:hover {
    color: rgb(74, 74, 79);
  }
}
