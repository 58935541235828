.wrapper {
  position: relative;
}

.menuContent {
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(110%);
  box-shadow: var(--boxShadowPopup);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 250px;
}

.menuLink {
  color: #222223;
  margin-bottom: 5px;
  &:hover {
    text-decoration: none;
    color: var(--marketplaceColor);
  }
}

.label {
  font-weight: 700;
  margin: 0 20px;
  color: black;
  &:hover {
    cursor: pointer;
    color: var(--marketplaceColor);
  }
}
