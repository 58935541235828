.wrapper {
  position: absolute;
  bottom: 0;
  transform: translateY(calc(100% - 10px));
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.columnsWrapper {
  width: 100%;
  padding: 30px 52px 30px 52px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 30%;
}

.columnItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 10px;
  &:hover {
    text-decoration: none;
    border-radius: 6px;
    background: rgba(167, 167, 167, 0.2);

    & .iconWrapper {
      background-color: var(--marketplaceColor);
    }

    & p {
      color: var(--marketplaceColor);
    }
  }
}

.columnTitle {
  color: #222223;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 20px;
}

.textWrapper {
  margin-left: 10px;
  & p {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: black;
    margin: 0;
  }

  & span {
    color: #475467;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

.iconWrapper {
  background-color: black;

  border-radius: 50%;
  padding: 10px;
}

.icon {
  height: 25px;
}

.ctaWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 0 8px 8px;
  padding: 28px;
  background: #f9fafb;
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.loginText {
  margin: 0;

  & a {
    margin-left: 10px;
  }
}

.link {
  color: black;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 15px;
  & img {
    height: 20px;
    margin-right: 5px;
  }
  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}
